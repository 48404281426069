import React, {FC, useState} from 'react';

import SubscriptionInfoModal from 'web/components/subscription_info_modal';
import {formatCents} from 'web/helpers/money';

export interface SubscriptionInfoProps {
  discount: number;
}

const SubscriptionInfo: FC<SubscriptionInfoProps> = (props) => {
  const [showSubscriptionInfoModal, setState] = useState(false);

  const handleOpenSubscriptionInfoModal = (): void => {
    setState(true);
  };

  const handleCloseSubscriptionInfoModal = (): void => {
    setState(false);
  };

  return (
    <div className="product-detail-page__subscription-info">
      {showSubscriptionInfoModal ? (
        <SubscriptionInfoModal onDismiss={handleCloseSubscriptionInfoModal} />
      ) : null}

      <div className="product-detail-page__subscription-info__details">
        <div className="product-detail-page__subscription-info__title">
          <i className="icon icon-subscriptions product-detail-page__subscription_info__icon-subscriptions" />
          Subscribe
        </div>
        <div className="product-detail-page__subscription-info__body">
          {props.discount != null && props.discount > 0 ? (
            <strong>Save ${formatCents(props.discount)}</strong>
          ) : (
            'Save time'
          )}{' '}
          with a weekly subscription.
          <br />
          Subscribe at checkout.
        </div>
        <a
          className="product-detail-page__subscription-info__learn-more"
          onClick={handleOpenSubscriptionInfoModal}
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

export default SubscriptionInfo;
