import React, {FC, Fragment} from 'react';
import {isEmpty, groupBy} from 'lodash';
import {formatDate} from 'goodeggs-formatters';
import classNames from 'classnames';
import Slider from 'react-slick';

import moment from 'infra/moment';
import {markupDescription} from 'web/helpers/markup';
import useClientSettings from 'web/hooks/useClientSettings';
import MarketTile from 'web/components/market_tile';
import {FeatureFlags} from 'web/helpers/experiments/feature_flags';

export interface Producer {
  city: string;
  name: string;
  shortDescription: string;
  slug: string;
  thumbnailUrl: string;
  url: string;
}

interface Tag {
  name: string;
  slug: string;
}

interface Warning {
  type: string;
  description?: string;
  link?: string;
}

export interface Product {
  id: string;
  availabilitiesByDay: {
    [day: string]: {
      status: string;
      quantity: number;
    };
  };
  currentProducer: Producer;
  description: string;
  hasVariableProducers: boolean;
  isDeliveryRestricted: boolean;
  otherProducers?: Producer[];
  quantityDetails?: string;
  recipe?: {
    ingredientsIncluded?: string[];
    ingredientsNotIncluded?: string[];
    instructions?: string[];
  };
  warning?: Warning;
}

interface AvailabilityStatusesProps {
  activePreorderPeriod: {
    preorderDays: string[];
  };
  deliveryRestrictedMinimumWindowStart: number;
  product: Product;
}

const AvailabilityStatuses: FC<AvailabilityStatusesProps> = (props) => {
  const clientSettings = useClientSettings();
  const {availabilitiesByDay, hasVariableProducers} = props.product;

  const availabilityItems = Object.keys(availabilitiesByDay)
    .sort()
    .map((day) => {
      const dayOfWeek = formatDate(
        moment.tz(day, clientSettings.tzid),
        'shortDayOfTheWeek',
        clientSettings.tzid,
      );
      const monthDay = formatDate(
        moment.tz(day, clientSettings.tzid),
        'monthDay',
        clientSettings.tzid,
      );
      const machineReadable = formatDate(
        moment.tz(day, clientSettings.tzid),
        'clockDate',
        clientSettings.tzid,
      );

      return (
        (props.activePreorderPeriod == null ||
          props.activePreorderPeriod.preorderDays[0] <= day) && (
          <li
            key={day}
            className={classNames('product-availability-status', availabilitiesByDay[day].status, {
              preorder: Boolean(props.activePreorderPeriod),
            })}
          >
            <time dateTime={machineReadable}>
              <div className="status">
                <div className="indicator" />
              </div>
              <div className="day-of-week">
                <span>{dayOfWeek}</span>
              </div>
              <div className="month-day">
                <span>{monthDay}</span>
              </div>
            </time>
          </li>
        )
      );
    });

  return (
    <section className="product-availability-details">
      <header>
        <h4 className="section-header">Available for Delivery</h4>
      </header>
      <ul className="product-availability-statuses">
        {availabilityItems.filter(Boolean).slice(0, 8)}
      </ul>
      {props.product.isDeliveryRestricted && (
        <div className="product-availability__restricted-notice">
          Available for orders starting after{' '}
          <b>
            {/* We can use an arbitary tz to reformat a *time* only, as long as it's the same to parse and to format. */}
            {moment.tz(`${props.deliveryRestrictedMinimumWindowStart}`, 'HH', 'utc').format('ha')}
          </b>
        </div>
      )}
      {hasVariableProducers ? (
        <span className="product-availability__multiple-producer-note">
          Good Eggs sources this product from multiple farms. It will come from one of the listed
          producers.
        </span>
      ) : null}
    </section>
  );
};

interface ProductDetailsProps {
  deliveryRestrictedMinimumWindowStart: number;
  product: Product;
  tags: Tag[];
}

const ProductDetails: FC<ProductDetailsProps> = (props) => (
  <section className="product-details">
    <header>
      <h4 className="section-header">Product Details</h4>
    </header>
    <QuantityDetails product={props.product} />
    <ProductDetailsDescription
      product={props.product}
      deliveryRestrictedMinimumWindowStart={props.deliveryRestrictedMinimumWindowStart}
    />
    <ProductFilters tags={props.tags} />
    {props.product.warning?.type === 'prop65' && props.product.warning.description && (
      <Prop65Warning
        description={props.product.warning.description}
        link={props.product.warning.link}
      />
    )}
  </section>
);

const GEK_NUTRITIONAL_INFO_URL =
  'https://help.goodeggs.com/hc/en-us/sections/360007686892-Good-Eggs-Kitchen-Nutritional-Information';

const isGEKProduct = (product: Product): boolean =>
  product.currentProducer.slug === 'goodeggskitchen';

interface ProductDetailsDescriptionProps {
  deliveryRestrictedMinimumWindowStart: number;
  product: Product;
}

const ProductDetailsDescription: FC<ProductDetailsDescriptionProps> = ({
  deliveryRestrictedMinimumWindowStart,
  product,
}) => {
  const {description, recipe} = product;
  // Maybe we shouldn't let assortment write any html or find a better way to sanitize it.
  /* eslint-disable react/no-danger */
  const descriptionElement =
    description && recipe ? (
      <div className="description-body" data-testid="description-body">
        {description && <div dangerouslySetInnerHTML={markupDescription(description)} />}
        {recipe.ingredientsIncluded != null && recipe.ingredientsIncluded.length > 0 ? (
          <>
            <p>
              <b>What You Get</b>
            </p>
            {recipe.ingredientsIncluded.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </>
        ) : null}
        {recipe.ingredientsNotIncluded != null && recipe.ingredientsNotIncluded.length > 0 ? (
          <>
            <p>
              <b>What You&apos;ll Need</b>
            </p>
            {recipe.ingredientsNotIncluded.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </>
        ) : null}
        {recipe.instructions != null && recipe.instructions.length > 0 ? (
          <>
            {recipe.instructions.map((item, index) => (
              <Fragment key={item}>
                <p>
                  <b>Step {index + 1}</b>
                </p>
                <p>{item}</p>
              </Fragment>
            ))}
          </>
        ) : null}
      </div>
    ) : (
      <div
        className="description-body"
        data-testid="description-body"
        dangerouslySetInnerHTML={markupDescription(product.description)}
      />
    );
  /* eslint-enable react/no-danger */

  return (
    <div className="product-details-description" itemProp="description">
      {product.isDeliveryRestricted && (
        <p className="product-details-page__restriction-copy">
          This item arrives at our facility later in the day. Therefore, it can only be ordered for
          delivery after{' '}
          {/* We can use an arbitrary tz to reformat a *time* only, as long as it's the same to parse and to format. */}
          {moment.tz(`${deliveryRestrictedMinimumWindowStart}`, 'HH', 'utc').format('ha')}.
        </p>
      )}
      {descriptionElement}
      {/* To satisfy legal requirements that came into effect in January 2020, display a stopgap
          external link to nutritional information for all Good Eggs-produced products. This will
          eventually be populated by PIM data.
          For more information, see: https://www.pivotaltracker.com/story/show/170588276 */}
      {isGEKProduct(product) && (
        <div className="product-details-page__nutritional-information-copy">
          <p>
            <a href={GEK_NUTRITIONAL_INFO_URL} rel="noreferrer noopener" target="_blank">
              View Nutritional Information
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

interface QuantityDetailsProps {
  product: Product;
}

const QuantityDetails: FC<QuantityDetailsProps> = (props) =>
  !isEmpty(props.product.quantityDetails) ? (
    <div data-testid="product-details" className="product-details">
      <div className="quantity-details">{props.product.quantityDetails}</div>
    </div>
  ) : null;

interface ProductFiltersProps {
  tags: Tag[];
}

const ProductFilters: FC<ProductFiltersProps> = (props) =>
  props.tags != null && props.tags.length > 0 ? (
    <div className="product-filters">
      <div className="filter">
        {props.tags.map((tag) => (
          <a key={tag.name} className="pill-link" href={`/t/${tag.slug}`}>
            {tag.name}
            <i className="icon icon-chevron" />
          </a>
        ))}
      </div>
    </div>
  ) : null;

interface Prop65WarningProps {
  description: string;
  link?: string;
}

const Prop65Warning: FC<Prop65WarningProps> = (props) => {
  const {description, link} = props;

  return (
    <div className="product-prop65" data-testid="product-prop65">
      <div className="title">
        <span className="icon icon-outlined-warning" />
        &nbsp;
        <b>Prop 65 Warning</b>
      </div>
      <div className="description">
        {description}{' '}
        {link && (
          <>
            For more information go to{' '}
            <a
              href={link}
              target="_blank"
              data-testid="product-prop65__link"
              rel="noopenner noreferrer"
            >
              {link}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

interface ProducerCardProps {
  producer: Producer;
  showDetail?: boolean;
}

const ProducerCard: FC<ProducerCardProps> = (props) => {
  const {producer, showDetail = false} = props;
  return (
    <div className="producer-detail-body">
      <div className="producer-detail-col1">
        <div className="producer-mobile-head-left">
          <a href={producer.url}>
            <img className="producer-thumbnail" src={producer.thumbnailUrl} alt={producer.name} />
          </a>
          {showDetail ? (
            <a className="webstand-link-desktop pill-link" href={producer.url}>
              See All Our Products
            </a>
          ) : null}
        </div>
        <div className="producer-mobile-head-right">
          <a href={producer.url}>
            <div className="producer-name-mobile" itemProp="name">
              {producer.name}
            </div>
          </a>
          <div
            className="producer-location-mobile"
            itemProp="address"
            itemScope
            itemType="http://schema.org/PostalAddress"
          >
            <span className="icon icon-pin" />
            <span itemProp="addressLocality">{producer.city}</span>
          </div>
          {showDetail ? (
            <a className="webstand-link-mobile pill-link" href={producer.url}>
              See All Products
            </a>
          ) : null}
        </div>
      </div>

      <div className="producer-detail-col2">
        <a className="producer-link" href={producer.url}>
          <div className="producer-name-desktop" itemProp="name">
            {producer.name}
          </div>
        </a>
        <div
          className="producer-location-desktop"
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress"
        >
          <span className="icon icon-pin" />
          <span itemProp="addressLocality">{producer.city}</span>
        </div>
        {showDetail ? (
          <div className="producer-description">
            <span className="producer-description__text">{producer.shortDescription}</span>
            <a className="read-more-link" href={producer.url}>
              Read more
              <span className="icon icon-chevron" />
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const Left = (props: {onClick?: () => void}): React.ReactElement => (
  <span className="slick-prev slick-prev--pdp icon icon-caret-left" onClick={props.onClick} />
);
const Right = (props: {onClick?: () => void}): React.ReactElement => (
  <span className="slick-next slick-next--pdp icon icon-caret-right" onClick={props.onClick} />
);

interface ProducerCarouselCardProps {
  producer: Producer;
}

const ProducerCarouselCard: FC<ProducerCarouselCardProps> = (props) => {
  const {producer} = props;
  return (
    <div className="producer-carousel-card">
      <a href={producer.url}>
        <img
          className="producer-carousel__thumbnail"
          src={producer.thumbnailUrl}
          alt={producer.name}
        />
      </a>
      <a href={producer.url}>
        <div className="producer-carousel__name" itemProp="name">
          {producer.name}
        </div>
      </a>
      <div
        className="producer-carousel__location"
        itemProp="address"
        itemScope
        itemType="http://schema.org/PostalAddress"
      >
        <span itemProp="addressLocality">{producer.city}</span>
      </div>
    </div>
  );
};

interface ProducerOtherProductsProps {
  producer: Producer;
  otherProductsIds: string[];
}

const ProducerOtherProducts = (props: ProducerOtherProductsProps): React.ReactElement => {
  const {producer, otherProductsIds} = props;
  const segmentFeature = 'pdp';
  return (
    <article className="producer-products">
      <header>
        <h2>
          <b>More products from {producer.name}</b>
        </h2>
      </header>
      <div className="producer-products__scrollbox">
        {otherProductsIds.map((productId, index) => (
          <MarketTile
            key={productId}
            productId={productId}
            position={index}
            segmentFeature={segmentFeature}
          />
        ))}
      </div>
    </article>
  );
};

interface ProducerDetailsProps {
  product: Product;
}

const ProducerDetails: FC<ProducerDetailsProps> = (props) => {
  const {currentProducer} = props.product;

  return (
    <section className="producer-detail">
      <header>
        <h4 className="section-header producer-detail__header">
          {props.product.hasVariableProducers ? 'Current Producer' : 'About The Producer'}
        </h4>
      </header>
      <ProducerCard producer={currentProducer} showDetail />
    </section>
  );
};

interface OtherProducerDetailsProps {
  otherProducers: Producer[];
}

const OtherProducerDetails: FC<OtherProducerDetailsProps> = (props) => {
  // Chunks the other producers into groups of 3
  const otherProducerSlides = Object.values(
    groupBy(props.otherProducers, (_: unknown, index: number) => Math.floor(index / 3)),
  );

  const carouselSettings = {
    dots: true,
    dotsClass: 'slick-dots slick-dots--pdp',
    nextArrow: <Right />,
    prevArrow: <Left />,
  };
  return (
    <section className="producer-detail other-producer-detail">
      <header>
        <h4 className="section-header">Other Producers</h4>
      </header>
      {props.otherProducers.length < 3 ? (
        props.otherProducers.map((producer, index) => (
          <ProducerCard producer={producer} key={index} />
        ))
      ) : (
        <Slider
          className={classNames('other-producer-carousel', {
            'other-producer-carousel--multipage': props.otherProducers.length > 3,
          })}
          {...carouselSettings}
        >
          {otherProducerSlides.map((producerSlide, slideIndex) => (
            <div
              data-testid="other-producer-carousel__slide"
              className="other-producer-carousel__slide"
              key={slideIndex}
            >
              {producerSlide.map((producer, index) => (
                <ProducerCarouselCard producer={producer as Producer} key={index} />
              ))}
            </div>
          ))}
        </Slider>
      )}
    </section>
  );
};

export interface ProductDescriptionProps {
  activePreorderPeriod: {
    preorderDays: string[];
  };
  deliveryRestrictedMinimumWindowStart: number;
  product: Product;
  producerProductsIds?: string[];
  tags: Tag[];
  features?: FeatureFlags[];
}

const ProductDescription: FC<ProductDescriptionProps> = (props) => {
  return (
    <div data-testid="product-description" className="product-description all-details clearfix">
      <ProductDetails
        product={props.product}
        tags={props.tags}
        deliveryRestrictedMinimumWindowStart={props.deliveryRestrictedMinimumWindowStart}
      />
      <AvailabilityStatuses
        product={props.product}
        activePreorderPeriod={props.activePreorderPeriod}
        deliveryRestrictedMinimumWindowStart={props.deliveryRestrictedMinimumWindowStart}
      />
      {props.features?.includes(FeatureFlags.ProductsFromProducer) &&
        props.producerProductsIds != null &&
        props.producerProductsIds.length > 0 && (
          <ProducerOtherProducts
            otherProductsIds={props.producerProductsIds}
            producer={props.product.currentProducer}
          />
        )}
      <ProducerDetails product={props.product} />
      {props.product.otherProducers != null && props.product.otherProducers.length > 0 ? (
        <OtherProducerDetails otherProducers={props.product.otherProducers} />
      ) : null}
    </div>
  );
};

export default ProductDescription;
