import React, {Component} from 'react';
import classNames from 'classnames';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {connect, ConnectedProps} from 'react-redux';

import ChoiceSelect from 'web/components/choice_select';
import UnavailabilityMessage from 'web/market/product_detail_page/components/unavailability_message';
import {AvailabilitiesByDay, PreorderPeriod, User} from 'web/basket/basket_page';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {actions as signUpModalActions} from 'web/helpers/sign_up_modal_duck';
import basketDuck from 'web/helpers/basket_duck';
import {ModalViewed} from '@analytics/client/generated';

interface Product {
  id: string;
  availabilitiesByDay: AvailabilitiesByDay;
  quantityOptions: Array<{quantity: number}>;
  orderTypes: string[];
  promotionalProductType: string;
}

interface Props extends ConnectedProps<typeof connector> {}

interface StateProps {
  features?: string[];
  user?: User;
}

interface DispatchProps {
  replaceModal: (modal: string, modalViewed: ModalViewed) => void;
  showSignUpModalFlow: () => void;
  setQuantitySelect: (productId: string, quantity: number) => void;
}

export interface ProductQuantitySelectorProps extends DispatchProps, StateProps {
  basketIsUpdating?: boolean;
  relatedProductsPath?: string;
  onQuantitySelect: ({productId, quantity}: {productId: string; quantity: number}) => void;
  quantityInBasket?: number;
  product: Product;
  currentShoppingDay: string;
  firstAvailableDay?: string;
  fulfillmentDaySummaries: Array<{day: string}>;
  onClickUnavailableCTA: (upcomingPreorderPeriod?: PreorderPeriod) => void;
  upcomingPreorderPeriods?: PreorderPeriod[];
  activePreorderPeriod?: PreorderPeriod;
  segmentFeature?: string;
}

interface ProductQuantitySelectorState {
  selectedQuantity: number;
}

class ProductQuantitySelector extends Component<
  ProductQuantitySelectorProps,
  ProductQuantitySelectorState
> {
  public constructor(props: ProductQuantitySelectorProps) {
    super(props);
    this.state = {
      selectedQuantity:
        this.props.quantityInBasket !== 0 && this.props.quantityInBasket !== undefined
          ? this.props.quantityInBasket
          : 1,
    };
  }

  public handleChangeSelectedQuantity = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const quantity = parseInt(e.target.value);

    if (this.props.quantityInBasket !== 0 && this.props.quantityInBasket !== undefined) {
      this.props.onQuantitySelect({productId: this.props.product.id, quantity});
    }

    this.setState({
      selectedQuantity: quantity ?? 1,
    });
  };

  public handleAddToBasket = (): void => {
    if (this.props.quantityInBasket === 0 || this.props.quantityInBasket === undefined) {
      this.props.onQuantitySelect({
        productId: this.props.product.id,
        quantity: this.state.selectedQuantity,
      });
    }
  };

  public render(): React.ReactNode {
    const {
      quantityInBasket,
      product,
      currentShoppingDay,
      fulfillmentDaySummaries,
      firstAvailableDay,
    } = this.props;

    const todaysAvailability = product.availabilitiesByDay[currentShoppingDay];
    const firstAvailableDayAvailability = product.availabilitiesByDay[firstAvailableDay ?? ''];
    const maxQuantity =
      todaysAvailability?.status === 'available'
        ? todaysAvailability.quantity
        : firstAvailableDayAvailability?.quantity ?? 0;

    const options = product.quantityOptions.filter(({quantity}) => quantity <= maxQuantity);

    if (!product.orderTypes.includes('single')) {
      return null;
    }

    // detect 'shopping mode' mismatches - if we're shopping for preorder but
    // product is only available during normal week, prompt to shop for normal
    // week, as well as the inverse.
    const {activePreorderPeriod} = this.props;
    if (
      !activePreorderPeriod &&
      firstAvailableDay &&
      !fulfillmentDaySummaries.find(({day}) => day === firstAvailableDay)
    ) {
      const preorderPeriod = this.props.upcomingPreorderPeriods?.find((preorder) =>
        preorder.preorderDays.includes(firstAvailableDay),
      );
      return (
        <UnavailabilityMessage
          onClickCTA={(): void => this.props.onClickUnavailableCTA(preorderPeriod)}
          heading={`This product is available for preorder for delivery week of ${
            preorderPeriod?.name ?? ''
          }.`}
          cta={`Preorder for ${preorderPeriod?.name ?? ''}`}
          variant="dark"
        />
      );
    } else if (
      activePreorderPeriod &&
      firstAvailableDay &&
      !activePreorderPeriod.preorderDays.includes(firstAvailableDay) &&
      fulfillmentDaySummaries.find(({day}) => day === firstAvailableDay)
    ) {
      return (
        <UnavailabilityMessage
          onClickCTA={(): void => this.props.onClickUnavailableCTA()}
          heading={`This product is not available for preorder.`}
          cta="Shop for this week"
        />
      );
    }

    // product is either not available at all or available somewhere else in the same 'shopping mode'
    if (todaysAvailability?.status !== 'available') {
      return (
        <UnavailabilityMessage
          relatedProductsPath={this.props.relatedProductsPath}
          heading={`Sorry, this product is ${
            todaysAvailability?.status === 'soldout' ? 'sold out' : 'unavailable'
          }.`}
          subheading={'Check back soon!'}
        />
      );
    }

    // Promotional products are limited to one per order
    if (product?.promotionalProductType !== 'none') {
      if ((quantityInBasket ?? 0) > 0) {
        return <UnavailabilityMessage heading={`Free gift added to basket!`} />;
      }
    }

    // we have availability!
    return (
      <div className="product-quantity">
        <div className="form-row quantity select">
          <label htmlFor="select-quantity" className="section-header">
            select a quantity
          </label>
          <ChoiceSelect
            className="product-detail-view__quantity-selector item-count"
            value={this.state.selectedQuantity}
            onChange={this.handleChangeSelectedQuantity}
          >
            {quantityInBasket !== 0 && quantityInBasket !== undefined ? (
              <option id="option-remove" value={0}>
                Remove
              </option>
            ) : null}
            {options.map(({quantity}) => (
              <option key={quantity} value={quantity}>
                {quantity}
              </option>
            ))}
          </ChoiceSelect>
        </div>
        <button
          className={classNames('btn', 'primary', 'save', {
            'in-basket': Boolean(quantityInBasket),
          })}
          type="button"
          onClick={this.handleAddToBasket}
        >
          {this.props.basketIsUpdating && 'updating basket...'}
          {!this.props.basketIsUpdating && Boolean(quantityInBasket)
            ? 'in basket'
            : 'add to basket'}
        </button>
      </div>
    );
  }
}

interface ReplaceModalProps {
  type: string;
  modal: string;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<DispatchProps, Props, AnyAction>,
): DispatchProps => ({
  replaceModal: (modal: string, modalViewed: ModalViewed): ReplaceModalProps =>
    dispatch(modalActions.replaceModal({modal, modalViewed})),
  showSignUpModalFlow: (): {type: string} => dispatch(signUpModalActions.showSignUpModalFlow()),
  setQuantitySelect: (productId: string, quantity: number): void => {
    dispatch(basketDuck.actions.setQuantitySelect(productId, 0, quantity));
  },
});

function mapStateToProps(state: StateProps): StateProps {
  return {
    features: state.features,
    user: state.user,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ProductQuantitySelector);
