import React, {FC} from 'react';

export interface MarketBreadcrumbsProps {
  category?: {name?: string; url?: string};
  subcategory?: {name?: string; url?: string};
  vendor?: {name?: string; url?: string};
  product?: {name?: string; url?: string};
}

export interface crumbType {
  name?: string;
  path?: string;
  type?: string;
}

const MarketBreadcrumbs: FC<MarketBreadcrumbsProps> = (props) => {
  const crumbs: crumbType[] = [
    {
      name: 'Home',
      path: `/`,
    },
    {type: 'separator'},
    {
      name: 'Market',
      path: `/home`,
    },
  ];

  if (props.category) {
    crumbs.push({type: 'separator'});
    crumbs.push({
      name: props.category.name,
      path: props.category.url,
    });
    if (props.subcategory) {
      crumbs.push({type: 'separator'});
      crumbs.push({
        name: props.subcategory.name,
        path: props.subcategory.url,
      });
    }
  }

  if (props.vendor) {
    crumbs.push({type: 'separator'});
    crumbs.push({
      name: props.vendor.name,
      path: props.vendor.url,
    });
  }

  if (props.product) {
    crumbs.push({type: 'separator'});
    crumbs.push({
      name: props.product.name,
    });
  }
  return (
    <div className="market-breadcrumbs">
      {crumbs.map((crumb, i) => {
        const key = `crumb-${i}`;
        if (crumb.type === 'separator') {
          return <span key={key} className="market-breadcrumbs__separator icon icon-chevron" />;
        }

        if (!crumb.path) {
          return (
            <span key={key} className="market-breadcrumbs__crumb">
              {crumb.name}
            </span>
          );
        }

        return (
          <a key={key} className="market-breadcrumbs__crumb" href={crumb.path}>
            {crumb.name}
          </a>
        );
      })}
    </div>
  );
};

export default MarketBreadcrumbs;
