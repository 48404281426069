import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';

import basketDuck from 'web/helpers/basket_duck';
import {actions as favoritesDuckActions} from 'web/helpers/favorites_duck';
import {actions as navigationActions} from 'web/helpers/navigation_duck';
import SubscriptionInfo from 'web/components/subscription_info';
import ProductQuantitySelector from 'web/market/product_detail_page/components/product_quantity_selector';
import SplitPrice from 'web/components/split_price';
import * as formatMoney from 'infra/formatters/money';
import {actions as modalActions} from 'web/helpers/modal_duck';
import useClientSettings from 'web/hooks/useClientSettings';

const segmentFeature = 'pdp';

function FavoriteControls(props) {
  return (
    <div
      onClick={props.handleFavorite}
      className={classNames('product-detail__favorite-control', {
        'not-favorited': !props.isFavorited,
        favorited: props.isFavorited,
      })}
    >
      <svg className="product-detail__favorite-control-heart" viewBox="8.9 7.8 18 16.633654">
        <path d="m 17.89989,23.433653 c 0.631042,-0.630881 4.233291,-4.23521 6.485296,-6.486896 2.017605,-2.017765 1.974565,-4.958732 0.140161,-6.783217 -1.834405,-1.8249642 -4.800652,-1.8169641 -6.625457,0.0176 -1.824645,-1.8345641 -4.790892,-1.8425642 -6.625297,-0.0176 -1.8344041,1.824485 -1.8772842,4.765452 0.140161,6.783217 2.251845,2.251686 5.854574,5.856015 6.485136,6.486896 l 0,0 z" />
      </svg>
      <div className="product-detail__favorite-control-description">
        {props.isFavorited ? 'Favorited' : 'Favorite'}
      </div>
    </div>
  );
}

FavoriteControls.propTypes = {
  isFavorited: PropTypes.bool.isRequired,
  handleFavorite: PropTypes.func.isRequired,
};

function ProductHeader(props) {
  return (
    <header className="product-header">
      {props.hasVariableProducers ? (
        <div className="current-producer-label">Current Producer</div>
      ) : null}
      <a className="producer-link" href={props.vendorUrl}>
        <h2 className="producer-name">{props.vendorName}</h2>
      </a>
      <h1 className="product-name">{props.name}</h1>
    </header>
  );
}

ProductHeader.propTypes = {
  name: PropTypes.string.isRequired,
  vendorName: PropTypes.string.isRequired,
  vendorUrl: PropTypes.string.isRequired,
  hasVariableProducers: PropTypes.bool,
};

function ProductPriceWithUnit(props) {
  const settings = useClientSettings();
  const [dollars, cents] = formatMoney.split(props.comparisonUnitPrice);
  const [baseDollars, baseCents] = formatMoney.split(props.basePrice);

  if (props.basePrice === 0) {
    return <div className="product-detail-page__promotional-product">FREE</div>;
  }
  const priceInCents = props.promoPrice ?? props.basePrice;

  return (
    <div>
      <div className="price-column">
        {props.promoPrice && settings.strikethroughPricing.enableWeb && (
          <div className="promo-container">
            <p className="high-price">
              ${baseDollars}.{baseCents}
            </p>
          </div>
        )}
      </div>

      <div className="price-row">
        <div className="price-row-item">
          <SplitPrice
            priceInCents={priceInCents}
            highlight={Boolean(props.promoPrice) && settings.strikethroughPricing.enableWeb}
            useSpan
          />
        </div>
        <div className="price-row-item">
          <div className="unit-detail quantity-unit">{props.retailUnits}</div>
          {props.comparisonUnitName ? (
            <div className="unit-detail unit-price">
              <span className="price-per">{`$${dollars}.${formatMoney.padCents(cents)} `}</span>/{' '}
              <span className="per-unit">{props.comparisonUnitName}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

ProductPriceWithUnit.propTypes = {
  /* meal kits don't have the comparison units */
  comparisonUnitName: PropTypes.string,
  comparisonUnitPrice: PropTypes.number,
  basePrice: PropTypes.number.isRequired,
  retailUnits: PropTypes.string.isRequired,
  promoPrice: PropTypes.number,
};

function PreorderHeader(props) {
  return (
    <div className="product-detail-page__preorder-header">
      <div className="product-detail-page__preorder-header__text">
        {props.preorderPeriod.name} Preorder
      </div>
    </div>
  );
}

PreorderHeader.propTypes = {
  preorderPeriod: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

class ProductControlPanel extends Component {
  handleFavorite() {
    if (this.props.isFavorited) {
      this.props.onUnFavorite({productId: this.props.product.id});
    } else {
      this.props.onFavorite({productId: this.props.product.id});
    }
  }

  render() {
    const preorderPeriod = this.props.upcomingPreorderPeriods?.find((preorder) =>
      preorder.preorderDays.includes(this.props.firstAvailableDay),
    );

    return (
      <div className={classNames('basket-controls', 'row-fluid')}>
        {preorderPeriod ? <PreorderHeader preorderPeriod={preorderPeriod} /> : null}

        <ProductHeader
          name={this.props.product.name}
          vendorName={this.props.product.vendor.name}
          vendorUrl={this.props.product.vendor.url}
          hasVariableProducers={this.props.product.hasVariableProducers}
        />

        <div>
          <ProductPriceWithUnit
            comparisonUnitName={this.props.product.comparisonUnitName}
            comparisonUnitPrice={this.props.product.comparisonUnitPrice}
            basePrice={this.props.product.basePrice}
            retailUnits={this.props.product.retailUnits}
            promoPrice={this.props.product.promoPrice}
          />

          <ProductQuantitySelector
            segmentFeature={segmentFeature}
            basketIsUpdating={this.props.basketIsUpdating}
            relatedProductsPath={this.props.relatedProductsPath}
            onQuantitySelect={this.props.onQuantitySelect}
            product={this.props.product}
            currentShoppingDay={this.props.currentShoppingDay}
            quantityInBasket={this.props.quantityInBasket}
            firstAvailableDay={this.props.firstAvailableDay}
            fulfillmentDaySummaries={this.props.fulfillmentDaySummaries}
            upcomingPreorderPeriods={this.props.upcomingPreorderPeriods}
            onClickUnavailableCTA={this.props.onClickUnavailableCTA}
            activePreorderPeriod={this.props.activePreorderPeriod}
          />

          {this.props.product.canSubscribe && this.props.user ? (
            <SubscriptionInfo
              discount={
                this.props.product.quantityOptions[0] != null
                  ? this.props.product.quantityOptions[0].quantitySubscriptionDiscount
                  : undefined
              }
            />
          ) : null}

          <FavoriteControls
            isFavorited={this.props.isFavorited}
            handleFavorite={this.handleFavorite.bind(this)}
          />
        </div>
      </div>
    );
  }
}

ProductControlPanel.propTypes = {
  user: PropTypes.object,
  zipCode: PropTypes.string,
  features: PropTypes.array,
  basketIsUpdating: PropTypes.bool,
  relatedProductsPath: PropTypes.string,
  onFavorite: PropTypes.func,
  onUnFavorite: PropTypes.func,
  isFavorited: PropTypes.bool,
  onQuantitySelect: PropTypes.func,
  reloadPage: PropTypes.func,
  currentShoppingDay: PropTypes.string,
  quantityInBasket: PropTypes.number,
  product: PropTypes.shape({
    id: PropTypes.string,
    canSubscribe: PropTypes.bool,
    quantityOptions: PropTypes.array,
    comparisonUnitName: PropTypes.string,
    comparisonUnitPrice: PropTypes.number,
    name: PropTypes.string.isRequired,
    retailPrice: PropTypes.number.isRequired,
    retailUnits: PropTypes.string.isRequired,
    vendor: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    hasVariableProducers: PropTypes.bool,
  }).isRequired,
  firstAvailableDay: PropTypes.string,
  fulfillmentDaySummaries: PropTypes.array,
  onClickUnavailableCTA: PropTypes.func.isRequired,
  upcomingPreorderPeriods: PropTypes.array,
  activePreorderPeriod: PropTypes.object,
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {dispatch} = dispatchProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,

    onClickUnavailableCTA: (preorderPeriod) =>
      dispatch(
        modalActions.openDayChooserModal({
          preorderPeriod,
          closeOnBack: true,
          redirectOnSelect: false,
          productAvailabilityDays: Object.entries(stateProps.product.availabilitiesByDay)
            .filter(([_key, value]) => value.status === 'available')
            .map(([key]) => key),
        }),
      ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onQuantitySelect: ({productId, quantity}) =>
      dispatch(
        basketDuck.actions.assignQuantity(
          {productId, quantity},
          {
            context: {feature: segmentFeature},
          },
        ),
      ),
    onFavorite: ({productId}) => dispatch(favoritesDuckActions.addFavorite(productId)),
    onUnFavorite: ({productId}) => dispatch(favoritesDuckActions.removeFavorite(productId)),
    reloadPage: () => dispatch(navigationActions.reload()),
    dispatch,
  };
}

function mapStateToProps(state) {
  const {product} = state.productDetails;
  const basketItem = state.basketItemsByProductId[product.id];

  return {
    product,
    user: state.user,
    zipCode: state.zipCode,
    basketIsUpdating: (state.basket.status && state.basket.status.isUpdating) || false,
    relatedProductsPath: state.productDetails.relatedProductsPath,
    currentShoppingDay: state.currentFulfillmentDay,
    quantityInBasket: (basketItem && basketItem.quantity) || 0,
    isFavorited: state.favorites.includes(product.id),
    firstAvailableDay: state.productDetails.firstAvailableDay,
    fulfillmentDaySummaries: state.fulfillmentDaySummaries,
    upcomingPreorderPeriods: state.upcomingPreorderPeriods,
    features: state.features,
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProductControlPanel);
