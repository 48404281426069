import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function UnavailabilityMessage(props) {
  return (
    <div className="product-detail-view__unavailable-apology">
      <p>
        {props.heading}
        <br />
        {props.subheading}
      </p>
      <p>
        {props.relatedProductsPath ? (
          <a className="button browse-link" href={props.relatedProductsPath}>
            See similar products
          </a>
        ) : props.cta ? (
          <button
            className={classNames('button', {
              dark: props.variant === 'dark',
            })}
            onClick={props.onClickCTA}
          >
            {props.cta}
          </button>
        ) : null}
      </p>
    </div>
  );
}

UnavailabilityMessage.propTypes = {
  relatedProductsPath: PropTypes.string,
  cta: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  onClickCTA: PropTypes.func,
  variant: PropTypes.string,
};
