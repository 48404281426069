import React, {Component} from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import MarketLayout from 'web/components/market_layout';
import MarketBreadcrumbs from 'web/components/market_breadcrumbs';
import {reducer as signInFlowReducer} from 'web/components/sign_in_flow/duck';
import segmentAnalytics from '@analytics/client';
import {ClientSettingsContext} from 'web/hooks/useClientSettings';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {
  reducer as signUpModalReducer,
  actions as signUpModalActions,
} from 'web/helpers/sign_up_modal_duck';
import ProductTag from 'web/components/product_tag';

import ProductControlPanel from './components/product_control_panel';
import ProductDescription from './components/product_description';

class ProductDetailPage extends Component {
  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;
  static reducer(state = {}, action) {
    let newState = MarketLayout.reducer(state, action);
    newState = signInFlowReducer(newState, action);
    newState = signUpModalReducer(newState, action);
    return newState;
  }

  componentDidMount() {
    const clientSettings = this.context;

    window.metrics.setPageProperty('productId', this.props.productDetails.product.id);
    segmentAnalytics.track('productViewed', {
      productId: this.props.productDetails.product.id,
      objectID: this.props.productDetails.product.id,
      index: clientSettings.search.algolia.index,
    });
  }

  render() {
    const {photoUrl, product, rawDescription} = this.props.productDetails;
    const {basePrice, promoPrice} = product;
    const isPromotional =
      this.context.strikethroughPricing.enableWeb && Boolean(basePrice) && Boolean(promoPrice);
    const title = `${product.name} | ${product.retailUnits} | ${product.vendor.name} | Good Eggs`;
    return (
      <MarketLayout
        categories={this.props.categories}
        foodhub={this.props.foodhub}
        user={this.props.user}
      >
        <Helmet>
          <title>{title}</title>
          <meta
            name="google-signin-client_id"
            content={this.props.settings.googleAuthenticateClientId}
          />
          <meta property="og:title" content={title} />
          <meta name="description" property="og:description" content={rawDescription} />
          <meta property="og:type" content="product" />
          <meta property="og:url" content={photoUrl} />
          <meta property="og:image" content={photoUrl} />
          <link rel="canonical" href={this.props.canonicalUrl} />
        </Helmet>

        <div
          className="product-detail-view"
          itemScope="itemscope"
          itemType="http://schema.org/Product"
          data-attribution-feature="product detail"
        >
          <MarketBreadcrumbs
            category={this.props.productDetails.breadCrumbs.category}
            subcategory={this.props.productDetails.breadCrumbs.subcategory}
            product={this.props.productDetails.product}
            vendor={this.props.productDetails.product.vendor}
          />

          <div className="photos">
            {isPromotional && (
              <ProductTag promoPrice={promoPrice} basePrice={basePrice} size="lg" />
            )}
            <img className="photo" src={photoUrl} data-src-large={photoUrl} />
          </div>

          <ProductControlPanel activePreorderPeriod={this.props.activePreorderPeriod} />
          <div className="all-details clearfix">
            <div style={{display: 'none'}} itemProp="name">
              {this.props.productDetails.product.name}
            </div>
            <ProductDescription
              product={this.props.productDetails.product}
              tags={this.props.productDetails.tags}
              activePreorderPeriod={this.props.activePreorderPeriod}
              deliveryRestrictedMinimumWindowStart={this.props.deliveryRestrictedMinimumWindowStart}
              features={this.props.features}
              producerProductsIds={this.props.producerProductsIds}
            />
          </div>
        </div>
      </MarketLayout>
    );
  }
}

ProductDetailPage.propTypes = {
  productDetails: PropTypes.shape({
    photoUrl: PropTypes.string.isRequired,
    tags: PropTypes.array,
    product: PropTypes.object.isRequired,
    breadCrumbs: PropTypes.shape({
      category: PropTypes.object,
      subcategory: PropTypes.object,
    }).isRequired,
  }).isRequired,
  user: PropTypes.object,
  foodhub: PropTypes.object,
  categories: PropTypes.array,
  activePreorderPeriod: PropTypes.object,
  deliveryRestrictedMinimumWindowStart: PropTypes.number.isRequired,
  settings: PropTypes.shape({
    googleAuthenticateClientId: PropTypes.string.isRequired,
  }).isRequired,
  replaceModal: PropTypes.func.isRequired,
  producerProductsIds: PropTypes.array,
  features: PropTypes.array,
  canonicalUrl: PropTypes.string.isRequired,
};

ProductDetailPage.pageName = 'Product Detail';

function mapStateToProps(state) {
  const activePreorderPeriod = state.upcomingPreorderPeriods?.find((preorder) =>
    preorder.preorderDays.includes(state.currentFulfillmentDay),
  );

  return {
    ...state,
    activePreorderPeriod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    replaceModal: (modal, props) => dispatch(modalActions.replaceModal({modal, props})),
    showSignUpModalFlow: () => dispatch(signUpModalActions.showSignUpModalFlow()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
